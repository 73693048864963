import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import React, { ReactElement } from "react";
import { FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import "./ErrorDisplay.scss";

import Header, { HeaderType } from "components/layout/Header/Header";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";

import { IconType, getIcon } from "util/Icon.util";

interface ErrorDisplayProps {
  error: SheetzError; //JS runtime could also throw an Error
  resetErrorBoundary: FallbackProps["resetErrorBoundary"];
}

const ErrorDisplay = (props: ErrorDisplayProps): ReactElement => {
  const navigate = useNavigate();

  function renderButton(buttonType?: SheetzErrorButtonType) {
    switch (buttonType) {
      case SheetzErrorButtonType.OK:
        return (
          <SheetzButton
            className="error-button"
            label="OK"
            onClick={() => props.resetErrorBoundary()}
          />
        );
      case SheetzErrorButtonType.CLOSE:
        return (
          <SheetzButton
            className="error-button"
            label="Close"
            onClick={() => props.resetErrorBoundary()}
          />
        );
      case SheetzErrorButtonType.TRY_AGAIN:
        return (
          <SheetzButton
            className="error-button"
            label="Try Again"
            onClick={() => props.resetErrorBoundary()}
          />
        );
      case SheetzErrorButtonType.SELECT_STORE:
        return (
          <SheetzButton
            className="error-button"
            label="Select Store"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/selectStore");
            }}
          />
        );
      case SheetzErrorButtonType.SELECT_TIME:
        return (
          <SheetzButton
            className="error-button"
            label="Select Time"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/time");
            }}
          />
        );
      case SheetzErrorButtonType.CHOOSE_TIME:
        return (
          <SheetzButton
            className="error-button"
            label="Choose Time"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/time");
            }}
          />
        );
      case SheetzErrorButtonType.SELECT_NEW_ITEM:
        return (
          <SheetzButton
            className="error-button"
            label="Select New Item"
            onClick={() => props.resetErrorBoundary()}
          />
        );
      case SheetzErrorButtonType.BACK_TO_ORDER:
        return (
          <SheetzButton
            className="error-button"
            label="Back To Order"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/menu");
            }}
          />
        );
      case SheetzErrorButtonType.CHOOSE_NEW_SIZE:
        return (
          <SheetzButton
            className="error-button"
            label="Choose New Size"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/menu");
            }}
          />
        );
      case SheetzErrorButtonType.CHECKOUT_AGAIN:
        return (
          <SheetzButton
            className="error-button"
            label="Checkout Again"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/paymentType");
            }}
          />
        );
      case SheetzErrorButtonType.SELECT_ADDRESS:
        return (
          <SheetzButton
            className="error-button"
            label="Choose New Address"
            onClick={() => {
              props.resetErrorBoundary();
              navigate("/order/delivery/address");
            }}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <Header type={HeaderType.main} title="Oh Sheetz!" />

      <div className="error-display">
        <div className="error-display-page">
          <div className="error-display-icon"> {getIcon(IconType.alert, "alert-icon")}</div>
          <div className="error-display-title">Son of a Shmiscuit!</div>
          <div className="error-display-message">
            {/* props.error could be a Generic Error. So we guard against the config property existing. */}
            {props.error.config?.userReadableMessage ?? "Looks like we've hit a snag!"}
          </div>

          {props.error.config === undefined && (
            <SheetzButton
              className="error-button"
              label="OK"
              onClick={() => {
                props.resetErrorBoundary();
                navigate("/order/menu");
              }}
            />
          )}

          {renderButton(props.error.config?.primaryButton)}

          {renderButton(props.error.config?.secondaybutton)}
        </div>
      </div>
    </>
  );
};

export default ErrorDisplay;
