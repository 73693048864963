import classNames from "classnames";
import React from "react";

import "./RestrictedItemDeliveryTermsActionSheet.scss";

import { DeliveryAddress } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { isInNativeMobileContext } from "util/MobileApp.util";

interface RestrictedItemDeliveryTermsActionSheetProps {
  show: boolean;
  setShowRestrictedItemDeliveryTermsActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
  deliveryAddress?: DeliveryAddress;
}

const buttonClasses = classNames("accept-button", {
  "in-mobile-app": isInNativeMobileContext(),
});

const RestrictedItemDeliveryTermsActionSheet = (
  props: RestrictedItemDeliveryTermsActionSheetProps
) => {
  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Get Prepared For 21+ Delivery"
      overlay={true}
      shouldDisplay={props.show}
    >
      <div className="restricted-item-delivery-terms">
        <div className="instructions">
          <p>
            <strong>
              <span className="number">1.</span>Meet your delivery driver in person
            </strong>
          </p>

          <p className="instruction">
            Your items can’t be left at the door, so schedule delivery when a 21+ adult is home.
          </p>
        </div>
        <div className="instructions">
          <p>
            <strong>
              <span className="number">2.</span>Bring your ID for age verification
            </strong>
          </p>
          <p className="instruction">
            Delivery drivers scan the recipient’s ID to verify their age. They’ll also collect a
            signature where required.
          </p>
        </div>

        <p className="disclaimer">
          You won’t receive any part of the order if the recipient is underage, intoxicated, or
          unavailable.
        </p>
        <div>
          <p className="disclaimer">
            By selecting Accept & Continue you acknowledge that your full name, birth date, and
            registered address have been entered and are accurate.
            {props.deliveryAddress?.stateCode === "MI" && (
              <>
                You also acknowledge that the sale of tobacco products to someone under 21 years old
                and the purchase of tobacco products by someone under 21 years old is illegal.
              </>
            )}
          </p>
        </div>
        <div className="accept-continue-button">
          <SheetzButton
            className={buttonClasses}
            label="Accept & Continue"
            onClick={(): void => props.setShowRestrictedItemDeliveryTermsActionSheet(false)}
          />
        </div>
      </div>
    </ActionSheet>
  );
};

export default RestrictedItemDeliveryTermsActionSheet;
