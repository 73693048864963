import React, { ReactElement, useContext, useState } from "react";
import { NumericFormat } from "react-number-format";

import "./GetRewardz.scss";

import { GetUserResponse, TierReward } from "assets/dtos/anywhere-dto";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import ResponsiveLayoutContainer from "components/misc/containers/ResponsiveLayoutContainer/ResponsiveLayoutContainer";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { IconType, getIcon } from "util/Icon.util";
import { purchaseReward } from "util/Rewardz.util";

interface RewardsProps {
  rewards?: TierReward[];
  userData?: GetUserResponse;
  reloadUserData: () => void;
}

interface RewardItemProps {
  reward: TierReward;
  icon?: ReactElement;
  purchaseable?: boolean;
}

const GetRewardz = (props: RewardsProps) => {
  const [showConfirmRewardMessage, setShowConfirmRewardMessage] = useState<boolean>(false);
  const [actionSheetReward, setActionSheetReward] = useState<TierReward>();
  const appContext = useContext(AppContext);

  const plusButtonIcon = getIcon(IconType.plus, "plus-icon");
  const lockButtonIcon = getIcon(IconType.lock, "lock-icon");

  const allRewards = props.rewards?.map((reward) => {
    return <RewardItem key={reward.offerId} reward={reward} />;
  });

  const confirmRewardMessage: ReactElement = (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Confirm Reward"
      shouldDisplay={showConfirmRewardMessage}
      overlay={true}
      required
    >
      <div className="action-sheet-rewardz-container">
        <div className="action-sheet-rewardz">
          <img
            className="action-sheet-rewardz-image"
            src={actionSheetReward?.imageURL}
            alt={actionSheetReward?.offerName}
          />

          <div className="action-sheet-rewardz-info">
            <div className="action-sheet-rewardz-name">{actionSheetReward?.offerName}</div>
            <div className="action-sheet-rewardz-detail">{actionSheetReward?.description}</div>
          </div>
        </div>

        <div className="action-sheet-btn-wrapper">
          <SheetzTextButton
            textDark
            className="action-sheet-decline-btn"
            label="No Thanks"
            onClick={(): void => setShowConfirmRewardMessage(false)}
          />
          <SheetzButton
            className="action-sheet-confirm-btn"
            label="Confirm"
            onClick={(): void => {
              if (actionSheetReward) {
                addNewReward(actionSheetReward);
              }
            }}
          />
        </div>
      </div>
    </ActionSheet>
  );

  function RewardItem(props: RewardItemProps): JSX.Element | null {
    const rewardItem: RewardItemProps = getRewardSetting(props.reward);

    if (rewardItem.reward) {
      return (
        <div className="get-rewardz-item">
          <img
            className="rewardz-image"
            src={rewardItem.reward.imageURL}
            alt={rewardItem.reward.offerName}
          />

          <div className="rewardz-info">
            <div className="rewardz-name">{rewardItem.reward.offerName}</div>
            <div className="rewardz-detail">{rewardItem.reward.description}</div>
          </div>

          <div className="rewardz-button-container">
            <SheetzButton
              className={"add-reward-button"}
              label={rewardItem.reward.pointCost + " Ptz"}
              label2={rewardItem.icon}
              disabled={!rewardItem.purchaseable}
              onClick={(event?: React.MouseEvent<HTMLButtonElement>): void => {
                event?.preventDefault();
                setActionSheetReward(rewardItem.reward);
                setShowConfirmRewardMessage(true);
              }}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  function getRewardSetting(reward: TierReward): RewardItemProps {
    const rewardInformation: RewardItemProps = {
      icon: lockButtonIcon,
      purchaseable: false,
      reward,
    };

    if (props.userData) {
      rewardInformation.icon =
        props.userData.loyaltyTierStatus.availablePoints > reward.pointCost
          ? plusButtonIcon
          : lockButtonIcon;

      rewardInformation.purchaseable =
        props.userData.loyaltyTierStatus.availablePoints > reward.pointCost;
    }

    return rewardInformation;
  }

  function addNewReward(reward: TierReward): void {
    purchaseReward({ offerId: reward.offerId }).then(() => {
      setShowConfirmRewardMessage(false);
      appContext.showToast(
        "Hurray! " + actionSheetReward?.offerName + " added.",
        "Please allow up to one minute for your pointz to be updated and the reward to be added!",
        ToastType.confirm
      );
    });

    setTimeout(() => {
      props.reloadUserData();
      appContext.hideToast();
    }, 10000);
  }

  if (props.rewards) {
    return (
      <ResponsiveLayoutContainer>
        <div className="get-rewardz-container">
          <div className="spendable-pointz">
            <NumericFormat
              value={props.userData?.loyaltyTierStatus.availablePoints}
              displayType="text"
              thousandSeparator
              suffix=" Spendable Pointz"
            />
          </div>

          <div className="rewardz-tier-container">{allRewards}</div>

          {confirmRewardMessage}
        </div>
      </ResponsiveLayoutContainer>
    );
  }

  return null;
};

export default GetRewardz;
