import { useFormikContext } from "formik";
import React, { useMemo } from "react";

import "./SheetzRadioGroup.scss";

import SheetzRadioButton from "components/misc/form/SheetzRadio/SheetzRadioButton/SheetzRadioButton";

interface SheetzRadioGroupProps {
  label?: string;
  name: string;
  options: { label?: string; value: string | number }[];
  required?: boolean;
  showErrors?: boolean;
}

const SheetzRadioGroup = ({
  label,
  name,
  options,
  required,
  showErrors,
}: SheetzRadioGroupProps) => {
  const { errors } = useFormikContext();

  const radioButtons = useMemo(() => {
    return options.map((option) => (
      <div className="top-margin" key={option.value}>
        <SheetzRadioButton
          name={name}
          value={option.value}
          label={option.label}
          required={required}
        />
      </div>
    ));
  }, [name, options, required]);

  return (
    <div className="sheetz-radio-group">
      {label && <p className="sheetz-radio-group-text">{label}</p>}

      {errors && showErrors && (
        <p className="sheetz-radio-group-error">{errors[name as keyof typeof errors]}</p>
      )}

      {radioButtons}
    </div>
  );
};

export default SheetzRadioGroup;
