import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import React, { useContext } from "react";
import { NumericFormat } from "react-number-format";
import * as Yup from "yup";

import "./CustomTipActionSheet.scss";

import { TipChoice } from "components/Order/PaymentFooter/PaymentFooterTipChoice/PaymentFooterTipChoice";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzInput from "components/misc/form/SheetzInput/SheetzInput";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { AppContext } from "util/AppContext.util";
import { isInNativeMobileContext } from "util/MobileApp.util";

interface CustomTipActionSheetProps {
  setShowNoTipActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOtherTipAmountActionSheet: React.Dispatch<React.SetStateAction<boolean>>;
  showOtherTipAmountActionSheet: boolean;
  updatePurchaseOrderWithTip: (tipChoice: TipChoice, otherAmount?: number) => void;
}
const buttonClasses = classNames("cancel-tip", {
  "in-mobile-app": isInNativeMobileContext(),
});

const CustomTipActionSheet = ({
  setShowNoTipActionSheet,
  setShowOtherTipAmountActionSheet,
  showOtherTipAmountActionSheet,
  updatePurchaseOrderWithTip,
}: CustomTipActionSheetProps) => {
  const appContext = useContext(AppContext);

  function setOtherTipAmount(values: { tip: number }): void {
    appContext.hideToast();

    setShowOtherTipAmountActionSheet(false);
    updatePurchaseOrderWithTip(TipChoice.Other, values.tip);

    if (values.tip === 0) {
      setShowNoTipActionSheet(true);
    }
  }

  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title="Other Tip Amount"
      overlay={true}
      shouldDisplay={showOtherTipAmountActionSheet}
    >
      <div className="other-tip-amount-container">
        <p className="tip-instructions">Add another tip amount here.</p>
        <Formik
          initialValues={{ tip: 0 }}
          validationSchema={Yup.object({
            tip: Yup.number().min(0),
          })}
          onSubmit={setOtherTipAmount}
        >
          {(formProps: FormikProps<{ tip: number }>) => (
            <form onSubmit={formProps.handleSubmit}>
              <NumericFormat
                name="tip"
                placeholder="Tip Amount"
                label="Tip Amount"
                type="tel"
                customInput={SheetzInput}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale
                onValueChange={(value): void => formProps.setFieldValue("tip", value.floatValue)}
              />

              <SheetzButton type="submit" label="Save" />
              <SheetzButton
                transparentDark
                className={buttonClasses}
                label="Cancel"
                type="button"
                onClick={(): void => {
                  setShowOtherTipAmountActionSheet(false);
                }}
              />
            </form>
          )}
        </Formik>
      </div>
    </ActionSheet>
  );
};

export default CustomTipActionSheet;
