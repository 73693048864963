import classNames from "classnames";
import React from "react";

import "./AlertActionSheet.scss";

import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import { isInNativeMobileContext } from "util/MobileApp.util";

interface AlertActionSheetProps {
  show: boolean;
  alertTitle?: string;
  alertMessage: string;
  confirmationButtonHandler: () => void;
}
const buttonClasses = classNames("confirm-button", {
  "in-mobile-app": isInNativeMobileContext(),
});

const AlertActionSheet = (props: AlertActionSheetProps) => {
  return (
    <ActionSheet
      color={ActionSheetColor.red}
      title={props.alertTitle ?? "Oh Sheetz!"}
      overlay={true}
      shouldDisplay={props.show}
    >
      <p className="alert-action-sheet-message">{props.alertMessage}</p>
      <SheetzButton
        className={buttonClasses}
        label="OK"
        onClick={props.confirmationButtonHandler}
      />
    </ActionSheet>
  );
};

export default AlertActionSheet;
