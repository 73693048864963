import classNames from "classnames";
import React from "react";
import { NumericFormat } from "react-number-format";

import "./PaymentTypeButton.scss";

import SheetzContainerButton from "components/misc/button/SheetzContainerButton/SheetzContainerButton";
import SheetzRadioButtonContainer from "components/misc/form/SheetzRadio/SheetzRadioButtonContainer/SheetzRadioButtonContainer";

import {
  PaymentButtonStatus,
  SelectedPaymentMethod,
  getCardName,
  getPaymentTypeIcon,
} from "util/Payment.util";

interface PaymentButtonProps {
  fetchPaymentMethods: boolean;
  isReloadGiftCard?: boolean;
  giftCardHasInsufficientFunds?: boolean;
  payInStore: boolean;
  onPaymentMethodClicked?: () => void;
  selectedPaymentMethod?: SelectedPaymentMethod;
}

const PaymentTypeButton = (props: PaymentButtonProps) => {
  const paymentMethod = props.selectedPaymentMethod?.paymentMethod;
  const enablePaymentSelection =
    !props.selectedPaymentMethod?.paymentRestriction &&
    !paymentMethod?.creditCard?.isExpired &&
    !props.giftCardHasInsufficientFunds;

  function getPaymentMethodExpAndBalance(): string | JSX.Element {
    if (!paymentMethod) {
      return "";
    }

    if (paymentMethod.paymentType === "CREDIT_CARD" && paymentMethod.creditCard) {
      return paymentMethod.creditCard.isExpired ? (
        <p className="payment-exp-balance low-funds-expired">Expired - Update Now</p>
      ) : (
        <p className="payment-exp-balance">
          Expires: {paymentMethod.creditCard.expirationMonth}/
          {paymentMethod.creditCard.expirationYear.toString().slice(2)}
        </p>
      );
    } else if (paymentMethod.zCard) {
      return (
        <p
          className={classNames("payment-exp-balance", {
            "low-funds-expired": props.giftCardHasInsufficientFunds,
          })}
        >
          <span>Balance&nbsp;</span>
          <NumericFormat
            value={paymentMethod?.zCard?.balance}
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
          />
        </p>
      );
    }

    return "";
  }

  function getPaymentMethod(): string | undefined {
    if (props.fetchPaymentMethods || paymentMethod) {
      // Credit card
      if (paymentMethod?.creditCard) {
        return (
          getCardName(paymentMethod?.creditCard?.cardType) +
          " " +
          paymentMethod?.creditCard?.lastFourDigits
        );
      }
    }

    // ZCARD
    if (paymentMethod?.zCard) {
      return paymentMethod?.zCard?.cardName;
    }

    // Pay in store
    if (props.payInStore) {
      return "Pay in-store";
    }
  }

  function getPaymentButtonStatus(): PaymentButtonStatus {
    const selectedPaymentMethod = props.selectedPaymentMethod;

    let checked;
    let disabled = false;

    if (paymentMethod) {
      if (selectedPaymentMethod && selectedPaymentMethod?.selected) {
        checked = true;
      }

      if (
        (props.giftCardHasInsufficientFunds && !props.isReloadGiftCard) ||
        selectedPaymentMethod?.paymentMethod?.creditCard?.isExpired
      ) {
        checked = false;
        disabled = true;
      }
    } else if (props.payInStore) {
      checked = true;
    }

    return { checked, disabled };
  }

  if (!paymentMethod) {
    return null;
  }

  return (
    <SheetzContainerButton
      className="payment-info-container"
      onClick={enablePaymentSelection ? props.onPaymentMethodClicked : undefined}
    >
      <SheetzRadioButtonContainer lightTheme>
        <input
          type="radio"
          id="paymentType"
          checked={getPaymentButtonStatus().checked}
          disabled={getPaymentButtonStatus().disabled}
        />

        <label htmlFor="paymentType">
          <span>
            <div className="payment-type-flex-container">
              {paymentMethod && getPaymentTypeIcon(paymentMethod)}

              <div className="payment-info-flex-container">
                <p className="payment-type">{getPaymentMethod()}</p>
                {getPaymentMethodExpAndBalance()}
              </div>
            </div>
          </span>
        </label>
      </SheetzRadioButtonContainer>
    </SheetzContainerButton>
  );
};

export default PaymentTypeButton;
