import { Field, useField } from "formik";
import React from "react";

import SheetzRadioButtonContainer from "components/misc/form/SheetzRadio/SheetzRadioButtonContainer/SheetzRadioButtonContainer";

interface SheetzRadioButtonProps {
  checked?: boolean;
  label?: string;
  name: string;
  required?: boolean;
  value?: string | number;
}

const SheetzRadioButton = ({ checked, label, name, required, value }: SheetzRadioButtonProps) => {
  const [field] = useField(name);

  return (
    <SheetzRadioButtonContainer>
      <Field
        {...field}
        type="radio"
        name={name}
        value={value}
        id={`${name}${value}`}
        checked={field.value === value ?? checked}
        required={required}
      />
      <label htmlFor={`${name}${value}`}>
        <span>{label ?? ""}</span>
      </label>
    </SheetzRadioButtonContainer>
  );
};

export default SheetzRadioButton;
