import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import "./SheetzRadioButtonContainer.scss";

interface SheetzRadioButtonContainerProps {
  lightTheme?: boolean;
}

const SheetzRadioButtonContainer = ({
  lightTheme,
  children,
}: PropsWithChildren<SheetzRadioButtonContainerProps>) => {
  const sheetzRadioButtonContainerClasses = classNames("sheetz-radio-button", {
    "light-theme": lightTheme,
  });

  return <div className={sheetzRadioButtonContainerClasses}>{children}</div>;
};

export default SheetzRadioButtonContainer;
