import axios, { AxiosError } from "axios";
import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BFFErrorResponse } from "axiosConfig";

import "./PaymentTypeChoice.scss";

import {
  CreatePurchaseOrderRequest,
  DeliveryAddress,
  MenuCategory,
  PaymentOptionDTO,
  PaymentOptionsRequest,
  PaymentOptionsResponse,
} from "assets/dtos/anywhere-dto";

import { OrderSubviewProps } from "components/Order/Order";
import AlcoholDeliveryActionSheet from "components/Order/RestrictedItem/AlcoholDeliveryActionSheet/AlcoholDeliveryActionSheet";
import RestrictedItemActionSheet from "components/Order/RestrictedItem/RestrictedItemActionSheet/RestrictedItemActionSheet";
import SheetzButton from "components/misc/button/SheetzButton/SheetzButton";
import SheetzTextButton from "components/misc/button/SheetzTextButton/SheetzTextButton";
import PathListItem from "components/misc/list/PathListItem/PathListItem";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";
import { ToastType } from "components/misc/view/SheetzToast/SheetzToast";

import { AppContext } from "util/AppContext.util";
import { isInNativeMobileContext } from "util/MobileApp.util";
import {
  createPaymentOptionsRequest,
  createPurchaseOrder,
  createPurchaseOrderRequest,
  getPaymentOptions,
  getTipChoiceFromPercentage,
} from "util/Order.util";

interface PaymentTypeChoiceProps extends OrderSubviewProps {
  deliveryAddress?: DeliveryAddress;
  menu: MenuCategory[];
}

const alcoholErrorMessage = "Alcohol cannot currently be delivered to this address.";

const PaymentTypeChoice = (props: PaymentTypeChoiceProps) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const [showRestrictedItemErrorActionSheet, setShowRestrictedItemErrorActionSheet] =
    useState(false);
  const [restrictedItemErrorText, setRestrictedItemErrorText] = useState<string | undefined>();
  const [showAlcoholDeliveryActionSheet, setSetShowAlcoholDeliveryActionSheet] = useState(false);
  const [showUnavailableActionSheet, setShowUnavailableActionSheet] = useState(false);
  const [unavailableReason, setUnavailableReason] = useState<string | undefined>();
  const [paymentOptionsResponse, setPaymentOptionsResponse] = useState<
    PaymentOptionsResponse | undefined
  >(undefined);

  const payInStoreOption: PaymentOptionDTO | undefined =
    paymentOptionsResponse?.paymentOptions.find((option) => option.paymentOption === "IN_STORE");
  const payOnlineOption: PaymentOptionDTO | undefined = paymentOptionsResponse?.paymentOptions.find(
    (option) => option.paymentOption === "ONLINE"
  );
  // For a delivery order, we only have the store number.
  const storeNumber = props.orderSession.store?.storeNumber ?? props.orderSession.storeNumber;

  const buttonClasses = classNames("payment-type-close-button", {
    "in-mobile-app": isInNativeMobileContext(),
  });

  useEffect(() => {
    if (
      storeNumber === undefined ||
      (!props.orderSession.delivery && !props.orderSession.pickupLocation) ||
      !props.orderSession.shoppingBag
    ) {
      throw new SheetzError("Order session data missing. Cannot get payment types", {
        userReadableMessage: "Store not selected. Please select a store and try again.",
        primaryButton: SheetzErrorButtonType.SELECT_STORE,
      });
    }

    if (!paymentOptionsResponse) {
      appContext.showLoading();

      const request: PaymentOptionsRequest = createPaymentOptionsRequest(
        storeNumber,
        isInNativeMobileContext() ? "MOBILE" : "WEB",
        props.orderSession.pickupLocation !== undefined
          ? props.orderSession.pickupLocation
          : "IN_STORE",
        props.orderSession.shoppingBag,
        props.orderSession.delivery
      );

      getPaymentOptions(request, props.orderSession.orderSessionId)
        .then((response) => {
          if (!response.data.available) {
            setUnavailableReason(response.data.message || "An error occurred.");
            setShowUnavailableActionSheet(true);
            setPaymentOptionsResponse(response.data);
          } else {
            setPaymentOptionsResponse(response.data);
          }
        })
        .catch((error: AxiosError<BFFErrorResponse>) => {
          navigate("/order", { replace: true });
        })
        .finally(() => appContext.hideLoading());
    }
  }, [
    appContext,
    navigate,
    paymentOptionsResponse,
    props.orderSession.delivery,
    props.orderSession.orderSessionId,
    props.orderSession.pickupLocation,
    props.orderSession.shoppingBag,
    storeNumber,
  ]);

  function paymentTypeSelected(payNow?: boolean): void {
    if (
      storeNumber === undefined ||
      (!props.orderSession.delivery && !props.orderSession.pickupLocation) ||
      !props.orderSession.shoppingBag
    ) {
      return;
    }

    appContext.showLoading();
    const request: CreatePurchaseOrderRequest = createPurchaseOrderRequest(
      storeNumber,
      isInNativeMobileContext() ? "MOBILE" : "WEB",
      props.orderSession.pickupLocation !== undefined
        ? props.orderSession.pickupLocation
        : "IN_STORE",
      payNow ? "PAY_ONLINE" : "PAY_IN_STORE",
      props.orderSession.shoppingBag,
      false, // This indicates that the automaticOffers were not returned
      props.orderSession.asapOrder ? undefined : props.orderSession.pickupTime,
      props.orderSession.delivery ? props.deliveryAddress?.deliveryAddressId : undefined,
      props.orderSession.deliveryTipChoice,
      props.orderSession.deliveryTipAmount
    );

    createPurchaseOrder(request, props.orderSession.orderSessionId)
      .then((response) => {
        const offerStatus = response.data.purchaseOrder.redeemableOfferStatus;
        // Let the user retry creating a purchase order with available rewards to redeem. On the second failure continue as normal.

        if (offerStatus && offerStatus === "FAILED") {
          navigate("/order/confirm");
        }

        props.dispatch({ type: "SAVE_PURCHASE_ORDER", payload: response.data.purchaseOrder });
        props.dispatch({
          type: "SET_DELIVERY_TIP_SUGGESTIONS",
          payload: response.data.suggestedDeliveryTips,
        });
        props.dispatch({ type: "SAVE_ORDER_TOKEN", payload: response.headers["order-token"] });
        if (response.data.purchaseOrder.integratedDelivery !== undefined) {
          if (response.data.purchaseOrder.integratedDelivery.deliveryTipPercentage !== undefined) {
            props.dispatch({
              type: "SET_DELIVERY_TIP_CHOICE",
              payload: getTipChoiceFromPercentage(
                response.data.purchaseOrder.integratedDelivery.deliveryTipPercentage
              ),
            });
          } else {
            props.dispatch({
              type: "SET_DELIVERY_TIP_AMOUNT",
              payload: response.data.purchaseOrder.integratedDelivery.deliveryTip,
            });
          }
        }
        if (
          payNow &&
          response.data.purchaseOrder.confirmableOffers &&
          response.data.purchaseOrder.confirmableOffers.length > 0 &&
          response.data.purchaseOrder.redeemableOfferStatus === "SUCCESS"
        ) {
          navigate("/order/confirmOffers");
        } else {
          navigate("/order/confirm");
        }
      })
      .catch((error: Error | AxiosError<BFFErrorResponse>) => {
        if (axios.isAxiosError(error)) {
          // Handle response code 451 (Not allowed for legal reasons) - relates to restricted item ordering.
          if (error.response?.status === 451) {
            setShowRestrictedItemErrorActionSheet(true);
            setRestrictedItemErrorText(error.response?.data.message);
            return;
          } else if (
            error.response?.status === 400 &&
            error.response.data.message === alcoholErrorMessage
          ) {
            setSetShowAlcoholDeliveryActionSheet(true);
            return;
          }

          appContext.showToast(
            "Oh Sheetz!",
            error.response?.data.message ?? "An unknown error has occurred.",
            ToastType.error,
            error.config.displayErrorInModal
          );
        }
      })
      .finally(appContext.hideLoading);
  }

  if (!paymentOptionsResponse) {
    return <></>;
  }

  return (
    <>
      <div className="payment-type-choice-container">
        <p className="heading">Payment Types</p>
        <ul>
          <li>
            <PathListItem
              title="Pay Now"
              subtitle={payOnlineOption?.message}
              disabled={!payOnlineOption?.available}
              clickHandler={(): void => paymentTypeSelected(true)}
            />
          </li>
          <li>
            <PathListItem
              title="Pay In-store"
              subtitle={payInStoreOption?.message}
              disabled={!payInStoreOption?.available}
              clickHandler={(): void => paymentTypeSelected()}
            />
          </li>
        </ul>
      </div>
      <RestrictedItemActionSheet
        show={showRestrictedItemErrorActionSheet}
        errorText={restrictedItemErrorText}
        setShowRestrictedItemErrorActionSheet={setShowRestrictedItemErrorActionSheet}
      />
      <AlcoholDeliveryActionSheet
        show={showAlcoholDeliveryActionSheet}
        orderSession={props.orderSession}
        dispatch={props.dispatch}
        setShowAlcoholDeliveryActionSheet={setSetShowAlcoholDeliveryActionSheet}
        menu={props.menu}
      />
      <ActionSheet
        color={ActionSheetColor.error}
        title="Oh Sheetz!"
        overlay={true}
        shouldDisplay={showUnavailableActionSheet}
      >
        <p className="payment-types-unavailable">{unavailableReason}</p>
        <div className="options-flex-container">
          <SheetzButton
            label="Change Store &amp; Pickup"
            onClick={(): void => navigate("/order/information", { replace: true })}
          />
          <SheetzButton
            transparentDark
            label="Edit Bag"
            onClick={(): void => navigate("/order/menu", { state: { showBag: true } })}
          />
          <SheetzTextButton
            textDark
            className={buttonClasses}
            label="Close"
            onClick={(): void => setShowUnavailableActionSheet(false)}
          />
        </div>
      </ActionSheet>
    </>
  );
};

export default PaymentTypeChoice;
